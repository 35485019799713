import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

interface UIClient extends Client {
  deleteInfo: string;
}

@autoinject
export class AdminClientsListRemoved {
  private showModalClientId?: number;
  private oldCertIsValid?: boolean;
  private readonly fieldList: FieldSpec[] = [
    { header: "common.name", key: "nickname", type: "text", cssClass: "not-clickable" },
    { header: "client.technicalName", key: "name", type: "text", cssClass: "not-clickable" },
    { header: "client.ipAddress", key: "ipAddress", type: "text", cssClass: "not-clickable" },
    { header: "client.created", key: "createTime", type: "date", cssClass: "not-clickable" },
    { header: "client.markedForDeletion", key: "markedForDeletion", type: "date", cssClass: "not-clickable" },
    { header: "client.deleteInfo", key: "deleteInfo", type: "text", cssClass: "not-clickable" },
    { header: "client.deleted", key: "deleteTime", type: "date", cssClass: "not-clickable" },
  ];

  backendList: UIClient[] = [];

  constructor(private readonly api: MyHttpApi, private readonly i18n: I18N, private readonly router: Router, private readonly notify: Notify) {
  }

  async activate() {
    const [backendList, infoMap] = await Promise.all([
      this.api.clientListDeleted(),
      this.api.clientDeleteInfoMap(),
    ])
    this.backendList = backendList.map(client => ({ ...client, deleteInfo: infoMap[client.id] || "" }));
  }

  async undelete(be: Client) {
    if (!confirm(this.i18n.tr("client.confirmUndelete"))) {
      return;
    }
    this.oldCertIsValid = await this.api.clientUndelete({ id: be.id });
    // * Show a modal telling that the server needs to be reinstalled if old certificate has been expired
    if(! this.oldCertIsValid) {
      this.showModalClientId = be.id;
      return;
    }

    this.notify.info("client.undeleted");
    this.router.navigateToRoute("admin/clients/show", { id: be.id });
  }
}
